/*!
 * https://github.com/YouCanBookMe/react-datetime
 */

.rdt {
  position: relative;
}
.rdtPicker {
  display: none;
  position: absolute;
  width: 250px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0,0,0,.1);
  border: 1px solid #f9f9f9;
}
.rdtOpen .rdtPicker {
  display: block;
}
.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}

.rdtPicker .rdtTimeToggle {
  text-align: center;
}

.rdtPicker table {
  width: 100%;
  margin: 0;
}
.rdtPicker td,
.rdtPicker th {
  text-align: center;
  height: 28px;
}
.rdtPicker td {
  cursor: pointer;
}
.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #eeeeee;
  cursor: pointer;
}
.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
  color: #999999;
}
.rdtPicker td.rdtToday {
  position: relative;
}
.rdtPicker td.rdtToday:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #428bca;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px;
}
.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: #428bca;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff;
}
.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
  color: #999999;
}
.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker th {
  border-bottom: 1px solid #f9f9f9;
}
.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
}
.rdtPicker th.rdtSwitch {
  width: 100px;
}
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top;
}

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker thead tr:first-child th {
  cursor: pointer;
}
.rdtPicker thead tr:first-child th:hover {
  background: #eeeeee;
}

.rdtPicker tfoot {
  border-top: 1px solid #f9f9f9;
}

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer;
}
.rdtPicker button:hover {
  background-color: #eee;
}

.rdtPicker thead button {
  width: 100%;
  height: 100%;
}

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
}
td.rdtMonth:hover,
td.rdtYear:hover {
  background: #eee;
}

.rdtCounters {
  display: inline-block;
}

.rdtCounters > div {
  float: left;
}

.rdtCounter {
  height: 100px;
}

.rdtCounter {
  width: 40px;
}

.rdtCounterSeparator {
  line-height: 100px;
}

.rdtCounter .rdtBtn {
  height: 40%;
  line-height: 40px;
  cursor: pointer;
  display: block;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;
}
.rdtCounter .rdtBtn:hover {
  background: #eee;
}
.rdtCounter .rdtCount {
  height: 20%;
  font-size: 1.2em;
}

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;
}

.rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px;
}

.month-picker {
  position: relative; }
  .month-picker > .container {
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    z-index: 100;
    top: 1px;
    left: -10000px;
    transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 450ms; }
    @media screen and (max-width: 767px) {
      .month-picker > .container {
        position: fixed;
        top: 0;
        left: -10000px;
        width: 100%;
        height: 100%;
        transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 450ms; } }
    .month-picker > .container.table {
      display: table; }
    .month-picker > .container.show {
      left: 0;
      transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms; }
      .month-picker > .container.show .overlay {
        left: 0; }
    .month-picker > .container .overlay {
      position: fixed;
      height: 100%;
      width: 100%;
      z-index: 9;
      top: 0;
      left: -10000px;
      opacity: 1;
      -webkit-tap-highlight-color: transparent;
      will-change: opacity;
      transform: translateZ(0);
      transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms; }
      @media screen and (max-width: 767px) {
        .month-picker > .container .overlay {
          background-color: rgba(0, 0, 0, 0.25); } }
    .month-picker > .container .cell {
      display: table-cell;
      vertical-align: middle;
      box-sizing: border-box;
      width: 100%;
      height: 100%; }
      @media screen and (max-width: 767px) {
        .month-picker > .container .cell {
          vertical-align: bottom; } }
    .month-picker > .container .popup {
      position: absolute;
      -webkit-tap-highlight-color: transparent;
      transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1);
      margin: 0 auto;
      z-index: 10;
      font-size: 1.2rem;
      opacity: 0;
      border-radius: 3px;
      padding: 0.4rem;
      box-sizing: content-box; }
      @media screen and (max-width: 767px) {
        .month-picker > .container .popup {
          box-sizing: border-box;
          bottom: 0;
          width: 100%;
          max-width: 100%;
          height: 14.4rem;
          transform: translate3d(0, 14.4rem, 0); }
          .month-picker > .container .popup.range {
            height: 28rem;
            transform: translate3d(0, 28rem, 0); }
            .month-picker > .container .popup.range .pad {
              margin-top: 0.4rem; }
              .month-picker > .container .popup.range .pad:first-of-type {
                margin-top: 0; } }
      @media screen and (min-width: 768px) {
        .month-picker > .container .popup {
          transform: translate3d(0, -64px, 0);
          top: 0;
          width: 20rem; }
          .month-picker > .container .popup.range {
            width: 40.6rem;
            padding: 0.6rem; } }
      .month-picker > .container .popup.show {
        opacity: 1;
        transform: translate3d(0, 0, 0); }
      .month-picker > .container .popup:after {
        content: ' ';
        clear: both;
        display: table; }
      .month-picker > .container .popup .pad {
        position: relative; }
        @media screen and (min-width: 768px) {
          .month-picker > .container .popup .pad {
            box-sizing: border-box;
            float: left;
            width: 20rem; }
            .month-picker > .container .popup .pad:nth-of-type(2) {
              float: right; } }
        .month-picker > .container .popup .pad > div label {
          display: block;
          font-size: 1.4rem;
          text-align: center;
          line-height: 3.4rem; }
          .month-picker > .container .popup .pad > div label b {
            font-weight: normal;
            margin-right: 0.5em; }
        .month-picker > .container .popup .pad > div i {
          font-style: normal;
          text-align: center;
          width: 3.4rem;
          height: 3.4rem;
          line-height: 3.4rem;
          position: absolute;
          top: 0; }
          .month-picker > .container .popup .pad > div i.prev {
            left: 0; }
          .month-picker > .container .popup .pad > div i.next {
            right: 0; }
        .month-picker > .container .popup .pad ul, .month-picker > .container .popup .pad li {
          list-style-type: none;
          margin: 0;
          padding: 0; }
        .month-picker > .container .popup .pad ul {
          display: block;
          width: 100%; }
          .month-picker > .container .popup .pad ul:after {
            content: ' ';
            clear: both;
            display: table; }
        .month-picker > .container .popup .pad li {
          display: block;
          float: left;
          text-align: center;
          line-height: 3.4rem;
          font-size: 1.15rem;
          border-radius: 2px;
          text-overflow: ellipsis;
          white-space: nowrap;
          *white-space: nowrap;
          overflow: hidden;
          -webkit-font-smoothing: antialiased;
          -webkit-text-stroke-width: 0.2px;
          -moz-osx-font-smoothing: grayscale;
          transition: background-color 200ms ease-in-out, color 200ms ease-in-out; }
          @media screen and (max-width: 767px) {
            .month-picker > .container .popup .pad li {
              width: 25%; } }
          @media screen and (min-width: 768px) {
            .month-picker > .container .popup .pad li {
              width: 33.3333333333%; } }
      .month-picker > .container .popup.light {
        color: #666;
        background-color: rgba(255, 255, 255, 0.96); }
        @media screen and (max-width: 767px) {
          .month-picker > .container .popup.light {
            border-top: 1px solid #ccc;
            box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.08); }
            .month-picker > .container .popup.light.range .pad {
              border-top: 1px solid rgba(204, 204, 204, 0.5); }
              .month-picker > .container .popup.light.range .pad:first-of-type {
                border-top: 0; } }
        @media screen and (min-width: 768px) {
          .month-picker > .container .popup.light {
            border: 1px solid #ccc;
            box-shadow: 0 1px 5px #ddd; }
            .month-picker > .container .popup.light.range .pad {
              background-color: rgba(238, 238, 238, 0.9); } }
        .month-picker > .container .popup.light .pad .btn {
          cursor: pointer; }
          @media screen and (min-width: 768px) {
            .month-picker > .container .popup.light .pad .btn:hover {
              background-color: rgba(255, 227, 160, 0.59); } }
        .month-picker > .container .popup.light .pad li.active, .month-picker > .container .popup.light .pad li.active:hover {
          background-color: rgba(31, 42, 58, 0.73);
          color: white;
          cursor: default; }
        .month-picker > .container .popup.light .pad .disable, .month-picker > .container .popup.light .pad .disable:hover {
          background-color: transparent;
          color: #bbb;
          cursor: default; }
      .month-picker > .container .popup.dark {
        color: #fff;
        background-color: rgba(50, 50, 50, 0.96); }
        @media screen and (max-width: 767px) {
          .month-picker > .container .popup.dark.range .pad {
            border-top: 1px solid rgba(113, 113, 113, 0.41); }
            .month-picker > .container .popup.dark.range .pad:first-of-type {
              border-top: 0; } }
        @media screen and (min-width: 768px) {
          .month-picker > .container .popup.dark.range .pad {
            background-color: rgba(70, 70, 70, 0.9); } }
        .month-picker > .container .popup.dark .pad .btn {
          cursor: pointer; }
          @media screen and (min-width: 768px) {
            .month-picker > .container .popup.dark .pad .btn:hover {
              background-color: rgba(255, 210, 96, 0.33); } }
        .month-picker > .container .popup.dark .pad li.active, .month-picker > .container .popup.dark .pad li.active:hover {
          background-color: rgba(189, 211, 242, 0.7);
          color: #303030;
          cursor: default; }
        .month-picker > .container .popup.dark .pad .disable, .month-picker > .container .popup.dark .pad .disable:hover {
          background-color: transparent;
          color: #717171;
          cursor: default; }

